<template>
  <div class="col-md-3 eqal-col" style="padding-left: 0">
    <div class="dashboard-left">
      <div class="dashboard-menu">
        <div class="gw-sidebar">
          <div id="gw-sidebar" class="gw-sidebar">
            <div class="nano-content">
              <ul class="dashboard-nav">
                <li class="list-inline-item">
                  <router-link :to="{ name: 'customer_dashboard' }" tag="a"
                  ><i class="icon ion-md-information-circle"></i
                  ><span class="gw-menu-text">Account Dashboard</span>
                  </router-link>
                </li>
                <li class="list-inline-item">
                  <router-link :to="{ name: 'accountInformation' }" tag="a"
                  ><i class="icon ion-md-information-circle"></i
                  ><span class="gw-menu-text">Account Information</span>
                  </router-link>
                  <!--<a href="#"
                    ><i class="icon ion-md-information-circle"></i
                    ><span class="gw-menu-text">Account Information</span>
                  </a>-->
                </li>
                <li class="list-inline-item">
                  <router-link :to="{ name: 'orderFromHistory' }" tag="a"
                  ><i class="icon ion-md-information-circle"></i
                  ><span class="gw-menu-text">Order from history</span>
                  </router-link>

                </li>
                <li class="list-inline-item">
                  <router-link :to="{ name: 'orderInformation' }" tag="a"
                  ><i class="icon ion-md-information-circle"></i
                  ><span class="gw-menu-text">My Orders</span>
                  </router-link>
                </li>
                <li class="list-inline-item">
                  <router-link :to="{ name: 'mywishlist' }" tag="a"
                    ><i class="icon ion-ios-list"></i
                    ><span class="gw-menu-text">My Wishlist</span>
                  </router-link>
                </li>
                <li class="list-inline-item" @click="confirmDisableId" style="cursor: pointer">
                  <a><i class="icon ion-ios-list"></i
                    ><span class="gw-menu-text">Delete Account</span>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="clickable" @click="logout"
                    ><i class="icon ion-ios-log-out"></i
                    ><span class="gw-menu-text">Log Out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
        ...mapGetters(["me", "accessToken"]),
    },
	methods: {
		logout() {
			this.$store.dispatch("logout");
			//window.localStorage.clear();
			this.$router.push({ name: "home" }).catch(() => {});
			//location.reload();
		},
		confirmDisableId() {
			if (confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
				this.disableId();
			}
		},
		async disableId() {
			try {
				// Call API to disable the customer account
				await this.$store.dispatch("disableId", { id: this.me.id });
				//alert("Your account has been disabled.");

				// Perform logout after disabling account
				//this.logout();
			} catch (error) {
				console.error("Error disabling account:", error);
				alert("An error occurred while disabling your account. Please try again.");
			}
		}
  },
};
</script>
<style scoped>
  .clickable{
    cursor: pointer;
  }
  a.router-link-exact-active{
    background: #464646 !important;
    color:#fff !important;
  }
  ul.dashboard-nav {
    margin: 0;
    padding: 0;
  }

  ul.dashboard-nav li {
    list-style: none;
    display: block;
  }
  ul.dashboard-nav li a:hover {
    background: #636c72 !important;
    color: #fff;
  }
  ul.dashboard-nav li a {
    display: block;
    padding: 10px 10px;
    color: #464646;
  }
  ul.dashboard-nav li a i {
    margin-right: 10px;
  }
</style>
