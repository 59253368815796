<template>
  <section class="product_details">
    <div class="container">
      <div class="dashboard">
        <div class="container-fluid">
          <div class="row">
            <div class="profile-top-bar">
              <div class="dashboard-breadcrumb">
                <div class="text-right" style="color: white">
                  {{ me.customer_name }}
                </div>
              </div>
            </div>
          </div>

          <div class="row row-flex">
            <Navbar></Navbar>

            <div class="col-md-9 eqal-col" style="padding-left: 5px">
              <div class="dashboard-right">
                <div class="dashboard-quotation">
                  <div class="container-fluid" id="dashboard_right">
                    <div class="col-md-9 eqal-col" style="padding-left: 5px">
                      <div class="dashboard-right">
                        <table class="table table-striped table-bordered" style="width: 100%">
                          <thead>
                          <tr>
                            <th>Order ID</th>
                            <th>Order Date</th>
                            <th>Grand Total</th>
                            <th>Used Point</th>
                            <th>Earned Point</th>
                          </tr>
                          </thead>
                          <tbody>
<!--                          <pre>{{customerPoints}}</pre>-->
                          <tr v-for="point in customerPoints.filter(e => e.product_orders !== null)" :key="point.id">
<!--                            <pre>{{point.product_orders?point.product_orders.id:'Not Found'}}</pre>-->
                            <td>{{point.product_orders_id}}</td>
                            <td>{{point.product_orders.order_date}}</td>
                            <td>{{point.product_orders.grand_total}}</td>
                            <td>{{point.used_point}}</td>
                            <td>{{point.earned_point}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/frontend/MyDashboard/Navbar.vue";
export default {
  computed: {
    ...mapGetters(["me","customerPoints"]),
  },
  components: {
    Navbar,
  },
  mounted() {
    this.$store.dispatch("getCustomerPoints");
  },
  methods:{
    removeItem(wid){
      this.$store.dispatch('removeWishlist', wid);
      this.$store.dispatch('getMe');
      this.$store.dispatch('getMe');
      this.$toasted.success('Successfully removed wish list item.');
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/dashboard.scss";
@import "@/assets/frontend/style/details.scss";
@import "@/assets/frontend/style/bs_leftnavi.scss";
@import "@/assets/frontend/style/customize.scss";
</style>